import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Heading from '@eversports/klimt-primitives/Heading'
import Text from '@eversports/klimt-primitives/Text'

import { Localized } from '../../localization/react'
import { RECAP_YEAR } from '../../App.constants'

import ShareIconSVG from './assets/share-icon.svg'

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isLoading: boolean
}

const RecapOverviewSharingButton = ({ onClick, isLoading }: Props) => {
  return (
    <Stack width="100%" gap={5} sx={{ paddingX: 2 }} data-exclude="true">
      <button
        className={`hover:bg-surface-brand-primary/90 m-auto w-fit rounded-md bg-surface-brand-primary px-4 py-1 ${
          isLoading && 'disabled:pointer-events-none disabled:opacity-50'
        }`}
        disabled={isLoading}
        onClick={onClick}
      >
        <Stack direction="row" alignItems="center" position="relative" sx={{ textTransform: 'none' }} gap={2}>
          <img src={ShareIconSVG} width="20px" height="20px" />
          <Heading is="h6" sx={{ color: 'white' }}>
            <Localized id="yearly-recap-share-button" params={{ year: RECAP_YEAR }} />
          </Heading>
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="size-2 animate-spin rounded-full border-2 border-solid border-border-brand-secondary border-t-transparent" />
            </div>
          )}
        </Stack>
      </button>
      <Text variant="xsmall" textAlign="center" sx={{ color: 'white' }}>
        <Localized id="yearly-recap-online-bookings-only-disclaimer" />
      </Text>
    </Stack>
  )
}

export default RecapOverviewSharingButton
